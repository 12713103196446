module.exports = {
  siteTitle: "Nicolas Cognaux (Gp2mv3) - Tech entrepreneur & engineer", // <title>
  shortSiteTitle: "Nicolas Cognaux", // <title> ending for posts and pages
  siteDescription:
    "This is my personal website. I blog about development, productivity, open source and entrepreneurship.",
  siteUrl: "https://gp2mv3.com",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Nicolas Cognaux - Tech entrepreneur & engineer",
  authorTwitterAccount: "gp2mv3",

  /* info */
  headerTitle: "Nicolas Cognaux - Tech entrepreneur & engineer",
  headerSubTitle: "I share about development, productivity, open source and entrepreneurship.",

  /* manifest.json */
  manifestName: "Gp2mv3 - Tech entrepreneur & engineer",
  manifestShortName: "Gp2mv3", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "https://www.gravatar.com/avatar/50830f4d4be229b0d71beb5c4b3bdf9e",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/gp2mv3" },
    { name: "twitter", url: "https://twitter.com/gp2mv3" }
  ]
};
